import { createStore, compose, applyMiddleware } from 'redux'
import rootReducer from './rootReducer'
import thunk from 'redux-thunk'

const isBrowser = () => typeof window !== "undefined"

export default function configureStore() {
  let composeEnhancers = compose

  // need to add redux dev tools on client side
  // but also handle ssr where window doesn't exist
  try {
    if (isBrowser()) {
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    }
  } catch(err) {
    // console.warn(err) // don't want to see window not existing errors
  }

  const createStoreWrapper = composeEnhancers(
    applyMiddleware(thunk)
  )(createStore)

  const store = createStoreWrapper(rootReducer)
  if (typeof window !== 'undefined') {
    // store.dispatch(firebaseActions.startListeningToAuthChanges() )
  }

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./rootReducer.js', () => {
  	  const nextReducer = require('./rootReducer.js')
  	  store.replaceReducer(nextReducer.default)
  	})
  }

  return store
}
