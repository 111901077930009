import React from 'react'
import { Provider } from 'react-redux'

import configureStore from './src/models/store'

const store = configureStore()

// const token = localStorage.getItem('github_token')

// if (token) {
//   // Update application state. User has token and is probably authenticated
//   store.dispatch({
//     type: 'AUTH_USER_WITH_GITHUB',
//     payload: token
//   })
// }

export const wrapRootElement = ({ element }) => {
  const ConnectedRootElement = (
    <Provider store={store}>
      {element}
    </Provider>
  )

  return ConnectedRootElement
}
