import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import initialState from './initialState'

// import firebaseReducer from './firebase'

import uiReducer from './ui'

function authReducer(state = initialState.auth, action = {}) {
  switch (action.type) {
    case 'RESET_AUTH_STATE':
      return initialState.auth
    case 'SIGN_USER_OUT':
      return initialState.auth
    case 'LOGIN_REQUESTED':
      return {
        ...state,
        pending: true,
        error: null,
        message: null
      }
    case 'LOGIN_FAILED':
      if (action.error && action.error.code) {
        if (action.error.code === 'auth/user-not-found') {
          return {
            ...initialState.auth,
            error: action.error,
            message: 'Incorrect email and password combination.'
          }
        }
      }
      if (action.error && action.error.code) {
        if (action.error.code === 'auth/wrong-password') {
          return {
            ...initialState.auth,
            error: action.error,
            message: 'Incorrect email and password combination.'
          }
        }
      }
      if (action.error && action.error.code) {
        if (action.error.code === 'auth/argument-error') {
          return {
            ...initialState.auth,
            error: action.error,
            message: 'Please enter a valid email and password.'
          }
        }
      }

      return {
        ...initialState.auth,
        pending: false,
        error: action.error,
        message: action.message
      }
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        status: 'AUTHENTICATED',
        error: null,
        message: null,
        loading: false
      }
    case 'SET_AUTHENTICATED_INFO':
      return {
        ...state,
        user: action.user,
        isAuthenticated: true,
        status: 'AUTHENTICATED'
      }
    case 'PASSWORD_RESET_EMAIL_SUCCESS':
      return {
        ...state,
        error: null,
        message: 'Password reset email sent.',
        loading: false,
        resetEmailSent: true
      }
    case 'PASSWORD_RESET_EMAIL_FAILED':
      if (action.error && action.error.code) {
        if (action.error.code === 'auth/user-not-found') {
          return {
            ...initialState.auth,
            resetEmailSent: false,
            error: action.error,
            message: 'Could not find a user with that email.'
          }
        }
      }
      return {
        ...state,
        error: 'auth/email_fail',
        message: 'Password reset email failed. Contact support.',
        loading: false
      }
    case 'FORCE_NAVIGATE_USER':
        return {
          ...state,
          message: action.message
        }
    default:
      return state
  }
}



const rootReducer = combineReducers({
  ui: uiReducer,
  auth: authReducer,
  form: formReducer
})

export default rootReducer
