import initialState from './initialState'

export default function uiReducer(
  state=initialState.ui,
  action={}
) {
  switch(action.type){
    case 'SWITCH_DATA_THEME':
      return {
        ...state,
        dataTheme: action.dataTheme
      }
    case 'COLLAPSE_SIDENAV':
      return {
        ...state,
        sidenavCollapsed: true
      }
    case 'OPEN_SIDENAV':
      return {
        ...state,
        sidenavCollapsed: false
      }
    default:
      return state
  }
}

export const Actions = {}

Actions.setDataTheme = function setDataTheme(theme){
  return dispatch => {
    dispatch({
      type: 'SWITCH_DATA_THEME',
      dataTheme: theme
    })
  }
}

Actions.collapseSidenav = function toggleSidenav(){
  return dispatch => dispatch({ type: 'COLLAPSE_SIDENAV' })
}

Actions.openSidenav = function openSidenav(){
  return dispatch => dispatch({ type: 'OPEN_SIDENAV'})
}
