const initialState = {
  auth: {
    status: 'ANONYMOUS',
    isAuthenticated: null,
    token: null,
    pending: false,
    message: null,
    error: null,
    user: null,
    resetEmailSent: false
  },
  ui: {
    dataTheme: 'dashboard-blues',
    sidenavCollapsed: false
  }
}

export default initialState
